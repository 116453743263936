import WebClientAuth from './client-auth.js'
import ClientWebRTC from './client-webrtc.js'
import ClientSocket from './client-socket.js'

async function waitForDevice({ publicKeySSH } = {}) {
  const containerHostName = `rinst-${makeid(11)}`
  console.log('abc: ok we loaded the way we wanted to', {
    publicKeySSH,
    deviceId: ClientWebRTC.getDeviceId(),
  })

  const socketInfo = await ClientSocket.socketOnline()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const jobName = urlParams.get('jobName') || 'runChromeInDocker'

  console.log('running job', jobName)

  const avalableServerList = await ClientWebRTC.getServersForJobType({
    socket: socketInfo.socket,
    serverJobType: jobName,
  })

  console.log('here are my available servers', avalableServerList)

  if (avalableServerList && avalableServerList.msg.serverList.length > 0) {
    console.log('yes there are available servers', avalableServerList)
    const selectedServer = avalableServerList.msg.serverList[0]

    console.log('i selected this server', selectedServer)

    const serverRes = await ClientWebRTC.executeJobOnServer({
      socket: socketInfo.socket,
      serverJobType: jobName,
      serverSessionId: selectedServer.sessionId,
      jobVariables: {
        containerHostName,
      },
    })

    console.log('ok server says', serverRes)

    const peerData = await ClientWebRTC.notifyWhenDeviceOnline({
      socket: socketInfo.socket,
      peerHostname: `${containerHostName}`,
      // peerDeviceId: argv.peerDeviceId,
      sessionId: uuidv4(),
      publicKeySSH,
    })

    const { sessionId } = peerData.msg

    window.location.href = `/novnc/vnc.html?sessionId=${sessionId}&port=5900&trueColor=1&shared=true&autoconnect=true&encrypt=true&resize=scale`
  }
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

function makeid(length) {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

if (window.publicKeySSH) {
  waitForDevice()
} else {
  console.log('so we are waitin 2')
  // WebClientAuth.AuthEvents.on('sshKeysGenerated', (data) => {
  //   console.log('got some keys', data)
  //   waitForDevice({ publicKeySSH})
  // })
}

export default {
  waitForDevice,
}
