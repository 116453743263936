import EventEmitter from 'eventemitter3'
const AuthEvents = new EventEmitter()

const publicExponent = new Uint8Array([1, 0, 1])

let rTermPrivateKey, rTermPublicKey

async function generateKeyPair() {
  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: 'RSASSA-PKCS1-v1_5',
      modulusLength: 2048,
      publicExponent,
      hash: { name: 'SHA-256' },
    },
    true,
    ['sign', 'verify'],
  )

  return keyPair
}

async function loadKeysFromLocalStorage() {
  const rTermPrivateKeyBase64 = localStorage.getItem('rterm-web-pk')
  const rTermPrivateKeyStr = window.atob(rTermPrivateKeyBase64)
  const rTermPrivateKeyAB = str2ab(rTermPrivateKeyStr)

  rTermPrivateKey = await crypto.subtle.importKey(
    'pkcs8',
    rTermPrivateKeyAB,
    {
      name: 'RSASSA-PKCS1-v1_5',
      modulusLength: 2048,
      publicExponent,
      hash: 'SHA-256',
    },
    true,
    ['sign'],
  )

  const rTermPublicKeyBase64 = localStorage.getItem('rterm-web-pub')
  const rTermPublicKeyStr = window.atob(rTermPublicKeyBase64)
  const rTermPublicKeyAB = str2ab(rTermPublicKeyStr)

  rTermPublicKey = await crypto.subtle.importKey(
    'spki',
    rTermPublicKeyAB,
    {
      name: 'RSASSA-PKCS1-v1_5',
      modulusLength: 2048,
      publicExponent,
      hash: 'SHA-256',
    },
    true,
    ['verify'],
  )

  const publicKeySSH = await window.crypto.subtle
    .exportKey('jwk', rTermPublicKey)
    .then((jwk) => encodePublicKey(jwk, 'rTerm-web'))
  console.log('rTerm Public SSH Key: ', { sshKey: publicKeySSH })
  window.publicKeySSH = publicKeySSH

  AuthEvents.emit('sshKeysGenerated', { publicKeySSH })
}

async function signPayload({ data }) {
  const buf = str2ab(data)
  const signatureAB = await crypto.subtle.sign(
    'RSASSA-PKCS1-v1_5',
    rTermPrivateKey,
    buf,
  )

  const signatureStr = ab2str(signatureAB)
  const signatureBase64 = window.btoa(signatureStr)

  return { signatureAB, signatureBase64 }
}

async function ensureKeyPairExists() {
  const newKeys = await generateKeyPair()

  await storeKeysInLocalStorage({ keyPair: newKeys })

  await loadKeysFromLocalStorage()
}

async function storeKeysInLocalStorage({ keyPair }) {
  if (!localStorage.getItem('rterm-web-pk')) {
    const exportedPrivateKeyAB = await window.crypto.subtle.exportKey(
      'pkcs8',
      keyPair.privateKey,
    )

    // const exportedKeyBuffer = new Uint8Array(exportedPrivateKeyAB);
    const exportedPrivateKeyStr = ab2str(exportedPrivateKeyAB)
    const exportedAsBase64 = window.btoa(exportedPrivateKeyStr)

    localStorage.setItem('rterm-web-pk', exportedAsBase64)
  }

  if (!localStorage.getItem('rterm-web-pub')) {
    const exportedPublicKeyAB = await window.crypto.subtle.exportKey(
      'spki',
      keyPair.publicKey,
    )
    const exportedPublicKeyStr = ab2str(exportedPublicKeyAB)
    const exportedPublicKeyBase64 = window.btoa(exportedPublicKeyStr)

    localStorage.setItem('rterm-web-pub', exportedPublicKeyBase64)
  }
}

async function authWithServer({ data, dataChannel, sessionId }) {
  if (data.type === 'token') {
    const signedPayloadResult = await signPayload({ data: data.token })

    dataChannel.send(
      JSON.stringify({
        type: 'tokenResponse',
        signedPayload: signedPayloadResult.signatureBase64,
        peerType: 'rterm-web',
        terminal: {
          rows: window.term ? window.term.rows : undefined,
          columns: window.term ? window.term.cols : undefined,
        },
        sessionId,
      }),
    )
  }
}

function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf))
}
function str2ab(str) {
  const buf = new ArrayBuffer(str.length)
  const bufView = new Uint8Array(buf)
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i)
  }
  return buf
}

console.log('ok client auth works')
ensureKeyPairExists()

/* Source https://github.com/PatrickRoumanoff/js-keygen */
function encodePublicKey(jwk, name) {
  const k = jwkToInternal(jwk)
  k.name = name
  const keyLenA = lenToArray(k.key.length)
  const exponentLenA = lenToArray(k.exponent.length)
  const typeLenA = lenToArray(k.type.length)
  const array = [].concat(
    typeLenA,
    stringToArray(k.type),
    exponentLenA,
    k.exponent,
    keyLenA,
    k.key,
  )
  const encoding = arrayToPem(array)
  return `${k.type} ${encoding} ${k.name}`
}

function jwkToInternal(jwk) {
  return {
    type: 'ssh-rsa',
    exponent: checkHighestBit(stringToArray(base64urlDecode(jwk.e))),
    name: 'name',
    key: checkHighestBit(stringToArray(base64urlDecode(jwk.n))),
  }
}

function checkHighestBit(v) {
  if (v[0] >> 7 === 1) {
    // add leading zero if first bit is set
    v.unshift(0)
  }
  return v
}

function lenToArray(n) {
  const oct = integerToOctet(n)
  let i
  for (i = oct.length; i < 4; i += 1) {
    oct.unshift(0)
  }
  return oct
}

function stringToArray(s) {
  return s.split('').map((c) => c.charCodeAt())
}

function base64urlDecode(s) {
  const step1 = s.replace(/-/g, '+') // 62nd char of encoding
  const step2 = step1.replace(/_/g, '/') // 63rd char of encoding
  let step3 = step2
  switch (
    step2.length % 4 // Pad with trailing '='s
  ) {
    case 0: // No pad chars in this case
      break
    case 2: // Two pad chars
      step3 += '=='
      break
    case 3: // One pad char
      step3 += '='
      break
    default:
      throw new Error('Illegal base64url string!')
  }
  return window.atob(step3) // Regular base64 decoder
}

function integerToOctet(n) {
  const result = []
  for (let i = n; i > 0; i >>= 8) {
    result.push(i & 0xff)
  }
  return result.reverse()
}

function arrayToPem(a) {
  return window.btoa(a.map((c) => String.fromCharCode(c)).join(''))
}

export default {
  generateKeyPair,
  signPayload,
  authWithServer,
  AuthEvents,
}
