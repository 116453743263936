import EventEmitter from 'eventemitter3'
import WebClientAuth from './client-auth.js'

const AppEvents = new EventEmitter()
const configuration = {
  iceServers: [
    {
      urls: 'stun:stun.l.google.com:19302',
    },
    {
      urls: 'turn:staay.in:3478',
      username: 'staayin-turn',
      credential: '123456',
    },
  ],
}

function registerWithSignalingServer({ socket, sessionId }) {
  return new Promise((resolve, reject) => {
    AppEvents.on('signalServerRegistration', (data) => {
      socket.clientId = data.data.clientId
      resolve(data)
    })

    socket.sendJSON({
      type: 'signalServerRegistration',
      sessionId,
      peerType: 'rterm-web-cli',
      deviceId: getDeviceId(),
      hostname: null,
    })
  })
}

function listActivePeers({ socket }) {
  return new Promise((resolve, reject) => {
    AppEvents.on('listActivePeers', ({ data, socket }) => {
      resolve(data)
    })

    socket.sendJSON({
      type: 'listActivePeers',
      peerType: 'rterm-web-cli',
      deviceId: getDeviceId(),
    })
  })
}

function getServersForJobType({ socket, serverJobType, jobVariables }) {
  return new Promise((resolve, reject) => {
    AppEvents.on('serverListForJobType', ({ data, socket }) => {
      console.log('do i fire at alle 1', data)
      resolve(data)
    })

    socket.sendJSON({
      type: 'getServersForJobType',
      serverJobType,
      jobVariables,
      trustedTrafficOnly: false,
      requestDeviceId: getDeviceId(),
    })
  })
}

function executeJobOnServer({
  socket,
  serverJobType,
  jobVariables,
  serverSessionId,
}) {
  return new Promise((resolve, reject) => {
    AppEvents.on('serverJobExecuted', ({ data, socket }) => {
      console.log('do i fire at alle 2', data)
      resolve(data)
    })

    socket.sendJSON({
      type: 'executeJobOnServer',
      serverJobType,
      jobVariables,
      trustedTrafficOnly: false,
      requestDeviceId: getDeviceId(),
      serverSessionId,
    })
  })
}

function notifyWhenDeviceOnline({
  socket,
  peerDeviceId,
  peerHostname,
  publicKeySSH,
}) {
  return new Promise((resolve, reject) => {
    AppEvents.on('peerDeviceIsOnline', ({ data, socket }) => {
      console.log('socket got some data')
      resolve(data)
    })

    socket.sendJSON({
      type: 'notifyWhenDeviceOnline',
      peerType: socket.peerType,
      deviceId: getDeviceId({ peerType: socket.peerType }),
      peerHostname,
      peerDeviceId,
      publicKeySSH,
    })
  })
}

function createRTCPeerConnection({ socket, sessionId, peerId }) {
  const pc = new RTCPeerConnection(configuration)
  pc.onicecandidate = ({ candidate }) =>
    sendIceCandidate({ candidate, socket, sessionId, peerId })

  return pc
}

function createDataChannel({ label, socket, pc, sessionId, isNew = true }) {
  const dataChannel = pc.createDataChannel(label)

  if (dataChannel.label === 'xterm') {
    dataChannel.onopen = () => {
      AppEvents.emit('dataChannelOpened', {
        dataChannel,
        label,
        pc,
        sessionId,
        socket,
      })

      dataChannel.send(
        JSON.stringify({
          type: 'sessionId',
          sessionId,
          peerType: 'rterm-web',
          timestamp: Date.now(),
        }),
      )

      if (window.term) {
        window.term.write('Connected. Authorizing. \r\n')
      }
    }
    dataChannel.lastCmd = ''
    dataChannel.onmessage = (msg) => {
      if (dataChannel.hasAuthed) {
        const currentCmd = msg.data.substr(0, msg.data.length - 1)
        if (window.term) {
          window.term.write(msg.data)
        }
      } else {
        try {
          const json = JSON.parse(msg.data)
          if (json.type === 'token') {
            WebClientAuth.authWithServer({ data: json, dataChannel, sessionId })
          } else if (
            json.type === 'authResponse' &&
            json.result === 'SUCCESS'
          ) {
            if (window.term) {
              window.term.write(
                'Succesfully Connected. rTerm Starting...\r\n\r\n',
              )
            }
            dataChannel.hasAuthed = true
            AppEvents.emit('dataChannelAuthorized', {
              dataChannel,
              sessionId,
              socket,
            })
          }
        } catch (ex) {
          console.log('Did not receive a token', ex)

          //FIXME: this is a temp hack for web interface, needs refactor
          if (window.term) {
            window.term.write(msg.data)
          }
          dataChannel.hasAuthed = true
          AppEvents.emit('dataChannelAuthorized', {
            dataChannel,
            sessionId,
            socket,
          })
        }
      }
    }

    dataChannel.onclose = () => {
      console.log('the peer connection closed')
    }

    dataChannel.sendCmd = (cmd) => {
      dataChannel.send(cmd)
      dataChannel.lastCmd = cmd.substr(0, cmd.length - 1)
    }
  } else {
    console.log('created another data channel?', label)
    // pc.dataChannel = createDataChannel({label: 'vnc', socket, pc, sessionId})

    pc.ondatachannel = (event) => {
      pc[event.channel.label] = event.channel

      event.channel.onopen = (evt) => {
        AppEvents.emit(`${event.channel.label}-open`, {
          dataChannel: event.channel,
          evt,
          sessionId,
          socket,
          pc,
        })
      }

      event.channel.onmessage = (evt) => {
        AppEvents.emit(`${event.channel.label}-message`, {
          evt,
          dataChannel: event.channel,
          sessionId,
          socket,
          pc,
        })
      }

      event.channel.onclose = (evt) => {
        AppEvents.emit(`${event.channel.label}-close`, {
          evt,
          dataChannel: event.channel,
          sessionId,
          socket,
          pc,
        })
      }
    }

    dataChannel.onopen = (evt) => {
      AppEvents.emit(`${label}-open`, {
        dataChannel,
        evt,
        sessionId,
        socket,
        pc,
      })
    }
  }

  return dataChannel
}

const vncChannel = `vnc-${uuidv4()}`
async function presentOffer({ socket, sessionId, peerId }) {
  if (window.term) {
    window.term.writeln('Connecting to peer...')
  }
  socket.pcs = socket.pcs || []
  const pc = createRTCPeerConnection({ socket, sessionId, peerId })

  pc.dataChannel = createDataChannel({ label: 'xterm', socket, pc, sessionId })
  // pc.vnc = createDataChannel({ label: vncChannel, socket, pc, sessionId})

  pc.ondatachannel = (event) => {
    pc[event.channel.label] = event.channel

    event.channel.onopen = (evt) => {
      AppEvents.emit(`${event.channel.label}-open`, {
        dataChannel: event.channel,
        evt,
        sessionId,
        socket,
        pc,
      })
    }

    event.channel.onmessage = (evt) => {
      AppEvents.emit(`${event.channel.label}-message`, {
        evt,
        dataChannel: event.channel,
        sessionId,
        socket,
        pc,
      })
    }

    event.channel.onclose = (evt) => {
      AppEvents.emit(`${event.channel.label}-close`, {
        evt,
        dataChannel: event.channel,
        sessionId,
        socket,
        pc,
      })
    }
  }

  const offer = await pc.createOffer()

  console.log('ill fire if there is a track')
  pc.ontrack = () => {
    console.log('anythig here?')
  }

  socket.pcs.push({
    peerId,
    pc,
    offer,
  })

  socket.sendJSON({
    type: 'offer',
    offer,
    sessionId,
    to: peerId,
    from: socket.clientId,
    requestId: uuidv4(),
  })
}

async function processAnswer({ data, socket }) {
  const peer = socket.pcs.find((x) => x.peerId === data.from)

  peer.pc.setLocalDescription(peer.offer)
  peer.pc.setRemoteDescription(data.answer)
}

async function sendIceCandidate({ candidate, socket, sessionId, peerId }) {
  socket.sendJSON({
    type: 'icecandidate',
    candidate,
    sessionId,
    to: peerId,
    from: socket.clientId,
  })
}

function processIceCandidate({ data, socket }) {
  const peer = socket.pcs.find((x) => x.peerId === data.from)

  if (data.candidate && peer) {
    peer.pc.addIceCandidate(data.candidate)
  }
}

function emit(eventName, data) {
  AppEvents.emit(eventName, data)
}

function registerEvents() {
  AppEvents.on('answer', processAnswer)
  AppEvents.on('icecandidate', processIceCandidate)
}

function getDeviceId() {
  let deviceId = localStorage.getItem('rterm-web-device-id')

  if (!deviceId) {
    deviceId = uuidv4()
    localStorage.setItem('rterm-web-device-id', deviceId)
  }

  return deviceId
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

registerEvents()

/* Should export this to another file? */
AppEvents.on('cmd-open', ({ dataChannel, evt, socket, pc, sessionId }) => {
  if (window.term) {
    window.term.onResize((size) => {
      dataChannel.send(
        JSON.stringify({
          type: 'resize',
          terminal: {
            rows: size.rows,
            columns: size.cols,
          },
        }),
      )
    })
  }

  dataChannel.send(
    JSON.stringify({
      type: 'vncSettingsRequest',
      vncChannel,
    }),
  )

  pc.vnc = createDataChannel({ label: vncChannel, socket, pc, sessionId })
})

AppEvents.on('cmd-message', ({ dataChannel, evt, sessionId, socket }) => {
  try {
    const json = JSON.parse(evt.data)
    if (json.type === 'vncSettings') {
      const vncLink = document.getElementById('vnc-available-on-selection')

      vncLink.href = `/novnc/vnc.html?sessionId=${sessionId}&port=${json.vncPort}&trueColor=${json.vncTC}`
      vncLink.target = '_blank'
      vncLink.style.display = 'inherit'
    }
  } catch (ex) {}
})

// const requestVNCChannel = () => {
//   dataChannel.send(
//     JSON.stringify({
//       type: 'vncSettingsRequest',
//       vncChannel,
//     }),
//   )
// }

AppEvents.on(`${vncChannel}-open`, ({ dataChannel, evt }) => {
  window.vncDC = dataChannel
  window.vncDC.on = AppEvents.on
})

AppEvents.on(`${vncChannel}-message`, ({ dataChannel, evt }) => {
  console.log('what does the vnc channel have to say', evt)
})

export default {
  registerWithSignalingServer,
  presentOffer,
  emit,
  listActivePeers,
  getDeviceId,
  AppEvents,
  createDataChannel,
  notifyWhenDeviceOnline,
  vncChannel,
  // requestVNCChannel,
  getServersForJobType,
  executeJobOnServer,
}
