import EventEmitter from 'eventemitter3'
import ClientWebRTC from './client-webrtc.js'
const SocketEvents = new EventEmitter()

function connectToSocket() {
  const ws = new WebSocket('wss://t.rterm.io/socket')
  // const ws = new WebSocket('ws://localhost:7080')
  console.log('does this do anything, socket connected')
  ws.addEventListener('message', function (msg) {
    const json = JSON.parse(msg.data)

    if (json.type) {
      ClientWebRTC.emit(json.type, { data: json, socket: ws })
    }
  })

  ws.sendJSON = (msg) => {
    const str = JSON.stringify(msg)
    ws.send(str)
  }

  return ws
}
console.log('does this do anything')
const ws = connectToSocket()

const urlParams = new URLSearchParams(window.location.search)
const sessionId = urlParams.get('sessionId')

ws.onopen = async () => {
  const regResponse = await ClientWebRTC.registerWithSignalingServer({
    socket: ws,
    sessionId,
  })

  regResponse.data.msg.otherClients
    .filter((x) => x.peerType === 'rterm-agent')
    .forEach((oc) => {
      ClientWebRTC.presentOffer({ socket: ws, sessionId, peerId: oc.clientId })
    })

  const onlinePeers = await ClientWebRTC.listActivePeers({ socket: ws })

  loadOnlinePeersUI({ onlinePeers: onlinePeers.msg.activePeers })

  SocketEvents.emit('clientSocketOpened', {
    socket: ws,
    onlinePeers: onlinePeers.msg.activePeers,
  })
}

function sendData(data) {
  ws.pcs.forEach((peer) => {
    if (
      peer.pc &&
      peer.pc.dataChannel &&
      peer.pc.dataChannel.readyState === 'open'
    ) {
      peer.pc.dataChannel.sendCmd(data)
    }
  })
}

function loadOnlinePeersUI({ onlinePeers }) {
  const onlinePeersElem = document.getElementById('online-peers')
  if (!onlinePeersElem) {
    return
  }
  onlinePeers.forEach((p) => {
    const optionElem = document.createElement('option')

    optionElem.label = p.hostname
    optionElem.text = p.hostname
    optionElem.value = p.sessionId

    onlinePeersElem.append(optionElem)
  })

  onlinePeersElem.onchange = function () {
    const vncLink = document.getElementById('vnc-available-on-selection')
    vncLink.style.display = 'none'

    const selectedPeer = onlinePeers.find((x) => x.sessionId === this.value)

    if (ws.pcs && ws.pcs.length > 0) {
      ws.pcs
        .filter((x) => x.peerId !== selectedPeer.clientId)
        .forEach((x) => {
          x.pc.close()
        })
      ws.pcs = ws.pcs.filter((x) => x.peerId !== selectedPeer.clientId)
      window.term.write('\r\n\r\n')
    }

    ClientWebRTC.presentOffer({
      socket: ws,
      sessionId: selectedPeer.sessionId,
      peerId: selectedPeer.clientId,
    })
  }
}

function socketOnline() {
  console.log('what is was', ws)
  if (ws && ws.readyState === 1) {
    console.log('socketOnline 1')
    return { socket: ws }
  }
  console.log('socketOnline 2')
  return new Promise((resolve, reject) => {
    SocketEvents.on('clientSocketOpened', (data) => {
      resolve(data)
    })
  })
}

export default {
  SocketEvents,
  socket: ws,
  socketOnline,
}
