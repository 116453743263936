import * as React from 'react'
import Avatar from '@mui/material/Avatar'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import SendIcon from '@mui/icons-material/Send'

import CssBaseline from '@mui/material/CssBaseline'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Divider from '@mui/material/Divider'

import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Typist from 'react-typist'
import {
  createMuiTheme,
  responsiveFontSizes,
  MuiThemeProvider,
} from '@mui/material'

import ClientAuth from './scripts/client-auth'
import ClientWebRTC from './scripts/client-webrtc'
import ClientSocket from './scripts/client-socket'
import IB from './scripts/ib'

let theme = createTheme()
theme = responsiveFontSizes(theme)

const shareWithOptions = [
  'your friends',
  'your cowokers',
  'clients',
  'classmates',
  'customers',
]

const ShareWithAnimation = () => {
  const [counter, setCounter] = React.useState(0)

  const doneTyping = () => {
    setCounter(counter + 1)
  }

  return (
    <>
      Share a browser with
      <Typist onTypingDone={doneTyping} key={`ani-${counter}`}>
        <Typist.Delay ms={1250} />
        {shareWithOptions.map((x) => {
          return (
            <span key={x}>
              {x}
              <Typist.Backspace count={x.length} delay={1000} />
            </span>
          )
        })}
        <Typist.Delay ms={1250} />
      </Typist>
    </>
  )
}

const StartSessionButton = () => {
  const [loading, setLoading] = React.useState(false)
  const handleStartSession = (event) => {
    event.preventDefault()
    setLoading(true)
    console.log('starting session')
    IB.waitForDevice()
  }

  return (
    <LoadingButton
      onClick={handleStartSession}
      loading={loading}
      loadingPosition="start"
      endIcon={<SendIcon />}
      variant="contained"
      width="300px"
      style={{ width: '300px' }}
    >
      Start Browser
    </LoadingButton>
  )
}

export default function MainPage() {
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          md={7}
          sx={{
            backgroundImage:
              'linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url(https://source.unsplash.com/random)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          minHeight="300px"
        >
          <Typography
            component="h2"
            color="white"
            variant="h2"
            position="relative"
          >
            <ShareWithAnimation />
          </Typography>
          <Typography component="h6" color="white" variant="h6" width="100%">
            Site walkthroughs made easy!
          </Typography>
        </Grid>
        <Grid item xs={12} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ mt: 3, mb: 2 }}>
              Welcome to Group Browse
            </Typography>

            <Box>
              <StartSessionButton />
            </Box>
            <Box>
              <Typography
                variant="p"
                component="p"
                fontSize="8pt"
                sx={{ mt: 3, mb: 2 }}
              >
                By using GroupBrowse you agree to our{' '}
                <a href="/">terms and conditions</a>
              </Typography>
            </Box>
            <Grid item xs={12} sm={8} md={11} lg={10}>
              <Box sx={{ mt: 3, mb: 2 }}>
                <Divider sx={{ mt: 3, mb: 2, width: '100%' }} />
                <Paper
                  sx={{
                    width: '100%',
                    mt: 3,
                    mb: 2,
                  }}
                  elevation={3}
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    sx={{ mt: 3, mb: 2, pt: 3 }}
                  >
                    How it works
                  </Typography>
                  <Divider sx={{ mt: 3, mb: 2, width: '100%' }} />
                  <List
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper',
                    }}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>1</Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Press Start Browser" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>2</Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="A new page will load with a running browser" />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>3</Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Share the new url with others" />
                    </ListItem>
                  </List>
                  <Divider sx={{ mt: 3, mb: 2, width: '100%' }} />
                  <Typography
                    component="p"
                    variant="p"
                    sx={{ mt: 3, mb: 2, p: 3 }}
                  >
                    Anyone with the link provided can join the session and share
                    control over the browser
                  </Typography>
                </Paper>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}
